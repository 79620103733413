<script>
import { 
  geneticPatternsDataType, 
  geneticPatternsDataTypeOptions, 
  categoriesAnimalDataType, 
  categoriesAnimalDataTypeOptions } from '@/utils/enums'
import gql from 'graphql-tag';
import { isNumber } from 'lodash';
import Swal from 'sweetalert2';

const INSERT_MUTATION = gql`
  mutation ($params_animals_insert_input: [params_animals_insert_input!]!) {
    insert_params_animals(
      objects: $params_animals_insert_input,
      ,
      on_conflict: {
        constraint: params_animals_pkey,
        update_columns: [weight, index_weight, index_pb, index_em, deleted_at],
      }
    ) {
      affected_rows
    }
  }
`;

export default {
  props: ['geneticPattern', 'categoryAnimal'],

  data() {
    return {
      loading: false,
      valid: true,
      inputDisabled: true,
      form: {},
      geneticPatterns: geneticPatternsDataType,
      geneticPatternsOptions: geneticPatternsDataTypeOptions,
      categoriesAnimal: categoriesAnimalDataType,
      categoriesAnimalOptions: categoriesAnimalDataTypeOptions,

      headers: [
        {
          text: 'Peso (kg)',
          value: 'weight',
        },
        {
          text: 'Fator Peso',
          value: 'index_weight',
        },
        {
          text: 'Fator PB',
          value: 'index_pb',
        },
        {
          text: 'Fator EM',
          value: 'index_em',
        },
        {
          text: 'Excluir',
          value: '_actions',
        },
      ],

      items: [],

      validations: {
        value: [(v) => !!v || isNumber(v) || 'Valor é obrigatório'],
      },
    };
  },

  async created() {
    this.form = {};
    this.items = [];
    if (this.geneticPattern === 'new' || this.geneticPattern === 'nova') {
      this.inputDisabled = false;
      return
    }
    this.items = await this.fetch();
    // delete this.form.id;
    delete this.form.__typename;

    // Clonar os dados
    if (this.$route.query.clone) {
      this.items.forEach(item => {
        item.id = undefined;
      });
      this.inputDisabled = false
    } else {
      this.form.genetic_pattern = this.geneticPattern;
      this.form.category_animal = this.categoryAnimal;
    }
  },

  methods: {
    async save() {
      if (!this.$refs.form.validate()) return false;
      this.loading = true;

      const params_animals_insert_input = this.items.filter(item => !(item.id == null && item.deleted))
        .map(item => {
          return {
              ...this.form,
              id: item.id,
              weight: item.weight,
              index_weight: item.index_weight,
              index_pb: item.index_pb,
              index_em: item.index_em,
              deleted_at: item.deleted ? new Date() : null,
            }
        });
    
      try {
        await this.$apollo.mutate({
          mutation: INSERT_MUTATION,
          variables: {
            params_animals_insert_input
          },
        });

        Swal.fire({
          icon: 'success',
          text: 'Registro alterado com sucesso!',
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.push('/parametros');

      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }

    },

    async fetch() {
      try {
        const result = await this.$apollo.query({
          query: gql`
            query Params($genetic_pattern: String!, $category_animal: String!) {
              params_animals(
                order_by: {weight: asc}, 
                where: {
                  genetic_pattern: {_eq: $genetic_pattern}, 
                  category_animal: {_eq: $category_animal}
                }
              ) {
                id
                weight
                index_weight
                index_pb
                index_em
              }
            }
          `,
          variables: {
            genetic_pattern: this.geneticPattern,
            category_animal: this.categoryAnimal,
          },
          fetchPolicy: 'no-cache',
        });
        return result.data.params_animals;
      } catch (e) {
        console.error(e);
      }
    },
    
    newLine() {
      this.items.push({
        id: undefined,
        weight: 0,
        index_weight: 0,
        index_pb: 0,
        index_em: 0,
      });
    },
  },
};
</script>

<template>
  <div>
    <h1 class="mb-5">Parâmetro</h1>

    <v-card class="pa-5">
      <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="save">
        <v-row>
          <v-col md="5" sm="12">
            <v-autocomplete
              v-model="form.genetic_pattern"
              :items="geneticPatternsOptions"
              :rules="validations.genetic_pattern"
              :disabled="inputDisabled"
              label="Padrão genético"
              dense
              outlined
              persistent-placeholder
            />
          </v-col>

          <v-col md="5" sm="12">
            <v-autocomplete
              v-model="form.category_animal"
              :items="categoriesAnimalOptions"
              :rules="validations.category_animal"
              :disabled="inputDisabled"
              label="Categoria animal"
              dense
              outlined
              persistent-placeholder
            />
          </v-col>
          <v-col md="2" sm="12">
            <v-btn
              color="primary"
              block
              @click="newLine"
            >
              Adicionar linha
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :items-per-page="100"
              :loading="$apollo.loading"
              class="elevation-1"
            >
              <template v-slot:[`item.weight`]="{ item }">
                <v-text-field
                  v-model="item.weight"
                  :rules="validations.value"
                  type="number"
                  outlined
                  dense
                  hide-details
                  class=""
                />
              </template> 
              <template v-slot:[`item.index_weight`]="{ item }">
                <v-text-field
                  v-model="item.index_weight"
                  :rules="validations.value"
                  type="number"
                  outlined
                  dense
                  hide-details
                  class=""
                />
              </template>
              <template v-slot:[`item.index_pb`]="{ item }">
                <v-text-field
                  v-model="item.index_pb"
                  :rules="validations.value"
                  type="number"
                  outlined
                  dense
                  hide-details
                  class=""
                />
              </template>
              <template v-slot:[`item.index_em`]="{ item }">
                <v-text-field
                  v-model="item.index_em"
                  :rules="validations.value"
                  type="number"
                  outlined
                  dense
                  hide-details
                  class=""
                />
              </template>
              <template v-slot:[`item._actions`]="{ item }">
                <v-checkbox
                  v-model="item.deleted"
                  label=""
                  hide-details
                  class="shrink mr-2 mt-0"
                />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="primary" plain class="mr-2" to="/parametros">Cancelar</v-btn>
            <v-btn color="success" :loading="loading" :disabled="loading" @click="save"> Salvar </v-btn>
          </v-col>
        </v-row>
        
      </v-form>
    </v-card>
  </div>
</template>
