var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"mb-5"},[_vm._v("Parâmetro")]),_c('v-card',{staticClass:"pa-5"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"md":"5","sm":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.geneticPatternsOptions,"rules":_vm.validations.genetic_pattern,"disabled":_vm.inputDisabled,"label":"Padrão genético","dense":"","outlined":"","persistent-placeholder":""},model:{value:(_vm.form.genetic_pattern),callback:function ($$v) {_vm.$set(_vm.form, "genetic_pattern", $$v)},expression:"form.genetic_pattern"}})],1),_c('v-col',{attrs:{"md":"5","sm":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.categoriesAnimalOptions,"rules":_vm.validations.category_animal,"disabled":_vm.inputDisabled,"label":"Categoria animal","dense":"","outlined":"","persistent-placeholder":""},model:{value:(_vm.form.category_animal),callback:function ($$v) {_vm.$set(_vm.form, "category_animal", $$v)},expression:"form.category_animal"}})],1),_c('v-col',{attrs:{"md":"2","sm":"12"}},[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":_vm.newLine}},[_vm._v(" Adicionar linha ")])],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":100,"loading":_vm.$apollo.loading},scopedSlots:_vm._u([{key:"item.weight",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"rules":_vm.validations.value,"type":"number","outlined":"","dense":"","hide-details":""},model:{value:(item.weight),callback:function ($$v) {_vm.$set(item, "weight", $$v)},expression:"item.weight"}})]}},{key:"item.index_weight",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"rules":_vm.validations.value,"type":"number","outlined":"","dense":"","hide-details":""},model:{value:(item.index_weight),callback:function ($$v) {_vm.$set(item, "index_weight", $$v)},expression:"item.index_weight"}})]}},{key:"item.index_pb",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"rules":_vm.validations.value,"type":"number","outlined":"","dense":"","hide-details":""},model:{value:(item.index_pb),callback:function ($$v) {_vm.$set(item, "index_pb", $$v)},expression:"item.index_pb"}})]}},{key:"item.index_em",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"rules":_vm.validations.value,"type":"number","outlined":"","dense":"","hide-details":""},model:{value:(item.index_em),callback:function ($$v) {_vm.$set(item, "index_em", $$v)},expression:"item.index_em"}})]}},{key:"item._actions",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"shrink mr-2 mt-0",attrs:{"label":"","hide-details":""},model:{value:(item.deleted),callback:function ($$v) {_vm.$set(item, "deleted", $$v)},expression:"item.deleted"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","plain":"","to":"/parametros"}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"success","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.save}},[_vm._v(" Salvar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }